import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';

// Services
import API from 'API';

// Components
import SearchBox from 'components/common/SearchBox';

// Images
import logoImage from 'images/logo.png';

//-------------------------------------------------------------------------------------------------------------------

class SideNav extends Component {

    async confirmLogOut() {
        const confirm = window.confirm('Are you sure you want to log out?');
        if (confirm) {
            await API.call('account/log-out');
            window.location.reload();
        }
    }

    render() {
        return (
            <aside className="side-nav">

                <img src={logoImage} className="logo" />

                <SearchBox
                    className="search-box"
                    placeholder="Search..."
                    minLength={1}
                    search={async (query, setResults, dataObj) => {
                        dataObj.nonce = `${Math.random()}`;
                        const response = await API.call('search', { query, nonce: dataObj.nonce });
                        if (response.nonce == dataObj.nonce) {
                            setResults(response.results);
                        }
                    }}
                    onClickResult={result => {
                        switch (result.type) {
                            case 'Customer':
                            case 'Supplier':
                            case 'Job':
                                this.props.history.push(`/${result.type.toLowerCase()}/${result.id}`);
                                break;
                            case 'JobDelivery':
                                this.props.history.push(`/job-delivery/${result.id}`);
                                break;
                            case 'PurchaseOrder':
                                this.props.history.push(`/purchase-order/${result.id}`);
                                break;
                        }
                    }}
                />

                <ul>
                    {/*
                    <li className={this.getNavClass([ '/sales-orders', '/sales-order' ])}>
                        <Link to="/sales-orders">
                            <span className="icon fa-solid fa-clipboard-list" />
                            Sales Orders
                        </Link>
                    </li>
                    */}
                    <li className={this.getNavClass(['/jobs', '/job'], ['/job-'])}>
                        <Link to="/jobs/open">
                            <span className="icon fa-solid fa-screwdriver-wrench"/>
                            Jobs
                        </Link>
                    </li>
                    <li className={this.getNavClass(['/job-deliveries', '/job-delivery'])}>
                        <Link to="/job-deliveries/open">
                            <span className="icon fa-solid fa-truck"/>
                            Delivery Notes
                        </Link>
                    </li>
                    <li className={this.getNavClass(['/purchase-orders', '/purchase-order'], ['/purchase-order-'])}>
                        <Link to="/purchase-orders/open">
                            <span className="icon fa-solid fa-dolly "/>
                            Purchase Orders
                        </Link>
                    </li>
                    {/*
                    <li className={this.getNavClass(['/purchase-order-deliveries', '/purchase-order-delivery'])}>
                        <Link to="/purchase-order-deliveries/open">
                            <span className="icon fa-solid fa-truck" />
                            PO Deliveries
                        </Link>
                    </li>
                    */}
                    <li className={this.getNavClass(['/customer', '/customers'])}>
                        <Link to="/customers">
                            <span className="icon fa-solid fa-person"/>
                            Customers
                        </Link>
                    </li>
                    <li className={this.getNavClass(['/suppliers', '/supplier'])}>
                        <Link to="/suppliers">
                            <span className="icon fa-solid fa-industry"/>
                            Suppliers
                        </Link>
                    </li>
                    <li className={this.getNavClass(['/open-orders'])}>
                        <Link to="/open-orders">
                            <span className="icon fa-solid fa-book-open"/>
                            Open Orders
                        </Link>
                    </li>
                    <li className={this.getNavClass(['/third-parties'])}>
                        <Link to="/third-parties">
                            <span className="icon fa-solid fa-arrows-turn-to-dots"/>
                            Third Parties
                        </Link>
                    </li>
                    <li className={this.getNavClass(['/products'])}>
                        <Link to="/products">
                            <span className="icon fa-solid fa-list" />
                            Products
                        </Link>
                    </li>
                    <li className={this.getNavClass(['/reports'])}>
                        <Link to="/reports">
                            <span className="icon fa-solid fa-clipboard-list" />
                            Reports
                        </Link>
                    </li>
                    <li className="mt-auto">
                        <Link to="#" onClick={e => {
                            this.confirmLogOut();
                            e.preventDefault();
                        }}>
                            <span className="icon fa-solid fa-door-open"/>
                            Log Out
                        </Link>
                    </li>
                </ul>

            </aside>
        );
    }

    getNavClass(paths, dontMatchPaths) {
        const {pathname} = this.props.location;
        if (paths.some(p => pathname.indexOf(p) == 0) &&
            (!dontMatchPaths || !dontMatchPaths.some(p => pathname.indexOf(p) == 0))
        ) {
            return 'active';
        }
        return '';
    }

}

export default withRouter(SideNav);
