// Libs
import React, { Component } from 'react';
import { withRouter } from "react-router-dom";

// Services
import API from 'API';

//-------------------------------------------------------------------------------------------------------------------

class NavMenu extends Component {

    constructor(props) {
        super(props);

        //this.onSearchResultClick = this.onSearchResultClick.bind(this);
        //this.addCustomer = this.addCustomer.bind(this);

        this.state = {
        };
    }

    //onSearchResultClick(searchResult, info) {
    //    let route = '';
    //    switch (searchResult.type) {
    //        case 'customer':
    //        case 'enquiry':
    //        case 'quote':
    //            route = `/${searchResult.type}/${searchResult.id}`;
    //            break;
    //    }
    //    if (route) {
    //        this.props.history.push(route);
    //    }
    //    //info.clearSearch();
    //}

    //addCustomer(query) {
    //    this.props.history.push('/customer/0/details/' + query);
    //}

    render() {
        return (
            <nav className="top-nav">


            </nav>
        );
    //    renderWrapper = {(input, results, info) => <>
    //        {input}
    //        {results &&
    //            <div className={'list search-results ' + (info.isActive ? 'active' : '')}>
    //                {results}
    //            </div>
    //        }
    //    </>
        //}
    //    renderResult = {(result, index, info) =>
    //    <div key={index} className="search-result list-item" onClick={e => this.onSearchResultClick(result, info)}>
    //        <div className="list-item-name">
    //            {result.name}
    //        </div>
    //    </div>
    //}

        //const loginInfo = GlobalStateService.getValue('loginInfo');
        //const wording = loginInfo.currentAccount.wording;
        //const user = loginInfo.user;
        
        //return (
        //    <nav className="top-nav">

        //        <div className="search-box-absolute">
        //            <Search
        //                className="search-box"
        //                placeholder={`Type to search for a ${wording.customerSingular}...`}
        //                minLength={3}
        //                search={async (query, setResults) => {
        //                    const results = await SearchService.search(query);
        //                    setResults(results);
        //                }}
        //                renderWrapper={(input, results, info) => <>
        //                    {input}
        //                    {results &&
        //                        <div className={'list search-results ' + (info.isActive ? 'active' : '')}>
        //                            {results}
        //                        </div>
        //                    }
        //                </>}
        //                renderResult={(result, index, info) =>
        //                    <React.Fragment key={index}>
        //                        {this.renderSearchResult(result, info)}
        //                    </React.Fragment>
        //                }
        //            />
        //        </div>

        //        <div className="account-selector">

        //            <select
        //                value={loginInfo.currentAccount.accountID}
        //                onChange={e => this.selectAccount(e.target.value)}
        //            >
        //                {loginInfo.accounts.map(a =>
        //                    <option key={a.accountID} value={a.accountID}>
        //                        {a.name}
        //                    </option>
        //                )}
        //            </select>

        //        </div>

        //    </nav>
        //);
    }

    //renderHighlightedField(searchResult, field, friendlyName, className, fallback, extraContent) {
    //    let value = searchResult.highlighted[field];
    //    if (value) {
    //        return (
    //            <div className={'sr-field ' + (className || '')}>
    //                {friendlyName ? friendlyName + ': ' : ''}
    //                {reactStringReplace(value, /\*([^\\*]*)\*/g, (match, index) => <em key={index}>{match}</em>)}
    //                {extraContent}
    //            </div>
    //        );
    //    }
    //    return fallback || '';
    //}
    
    //renderSearchResult(result, info) {
    //    const loginInfo = GlobalStateService.getValue('loginInfo');
    //    const wording = loginInfo.currentAccount.wording;

    //    switch (result.type) {
    //        case 'customer':
    //        case 'enquiry':
    //        case 'quote':

    //            let className;
    //            let typeName;
    //            let iconClassName;
    //            let extraText;

    //            switch (result.type) {
    //                case 'customer':
    //                    typeName = wording.customerSingularTitle;
    //                    iconClassName = 'far fa-address-card';
    //                    if (result.isOneOff && loginInfo.settings.enableSchedules) {
    //                        className = 'one-off';
    //                        extraText = ' (One-off)';
    //                    } else if (result.isInactive) {
    //                        className = 'inactive';
    //                        extraText = ' (Inactive)';
    //                    } else {
    //                        className = 'active';
    //                    }
    //                    break;
    //                case 'enquiry':
    //                    typeName = 'Enquiry';
    //                    iconClassName = 'far fa-question-circle';
    //                    break;
    //                case 'quote':
    //                    typeName = 'Quote';
    //                    iconClassName = 'far fa-file-alt';
    //                    break;
    //            }

    //            return (
    //                <div className={`search-result search-result-${result.type} ${className}`} onClick={e => this.onSearchResultClick(result, info)}>
    //                    <div className="sr-icon">
    //                        <span className={iconClassName}></span>
    //                    </div>
    //                    <div className="sr-content">
    //                        {typeName &&
    //                            <div className="sr-type">{typeName}</div>
    //                        }
    //                        {this.renderHighlightedField(result, 'name', '', 'sr-name', result.name, extraText)}
    //                        {this.renderHighlightedField(result, 'reference', 'Reference')}
    //                        {this.renderHighlightedField(result, 'phoneNumber', 'Phone')}
    //                        {this.renderHighlightedField(result, 'email', 'Email')}
    //                        {this.renderHighlightedField(result, 'address', 'Address')}
    //                    </div>
    //                </div>
    //            );
    //    }
    //    return (<>
    //        {result.name}
    //    </>);
    //}

}

export default withRouter(NavMenu);