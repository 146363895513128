import React, {createRef, useEffect, useState} from 'react';
import API from 'API';
import moment from "moment";
import {
    Box,
    Button,
    Center,
    Checkbox,
    Flex,
    LoadingOverlay,
    Table,
    Tooltip,
    NumberFormatter
} from "@mantine/core";
import {useHistory} from "react-router-dom";
import EditOpenOrderModal from "./EditOpenOrderModal";
import {notifications} from "@mantine/notifications";
import {IconCheck} from "@tabler/icons-react";
const OpenOrdersPage = () => {
    const successIcon = <IconCheck style={{ width: '20px', height: '20px' }} />;
    
    const [openOrders, setOpenOrders] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isExportingToExcel, setIsExportingToExcel] = useState(false);

    const editOpenOrderModalRef = createRef();

    let history = useHistory();
    
    useEffect(() => {
        async function loadOrders() {
            const openOrdersList = await API.call('open-orders/list');
            setOpenOrders(openOrdersList);
        }

        loadOrders().then(() => setIsLoading(false));
    }, []);

    const openEditOpenOrderModal = (openOrder) => {
        editOpenOrderModalRef.current.open(openOrder);
    }

    const onOpenOrderSaved = (openOrder) => {
        let openOrdersCopy = [...openOrders];

        let editedOpenOrder = openOrdersCopy.find(o => o.jobItemId === openOrder.jobItemId);

        editedOpenOrder.supplierPONumber = openOrder.supplierPONumber;
        editedOpenOrder.isThirdParty = openOrder.isThirdParty;
        
        if (editedOpenOrder.isThirdParty) {
            let editedOpenOrderIndex = openOrdersCopy.indexOf(editedOpenOrder);

            openOrdersCopy.splice(editedOpenOrderIndex, 1);    
        }
        
        setOpenOrders(openOrdersCopy);

        notifications.show({
            title: 'Success',
            message: 'Your changes have been saved',
            icon: successIcon,
            color: 'teal'
        })
    }

    const printOpenOrders = async () => {
        setIsExportingToExcel(true);
        const notificationId = notifications.show({
            title: 'Export to excel',
            message: 'Generating data...',
            loading: true,
            autoClose: false
        });

        try {
            await API.call('open-orders/print', {
                isDownload: true
            });
        } catch {
        } finally {
            notifications.hide(notificationId);
            setIsExportingToExcel(false);
        }
    }

    const Header = () => {
        return (
            <section>
                <Flex justify="flex-end" direction="row">
                    <Button
                        variant="outline"
                        loading={isExportingToExcel}
                        loaderProps={{type: 'oval'}}
                        onClick={() => printOpenOrders()}>
                        Export to excel
                    </Button>
                </Flex>
            </section>
        );
    }

    const OpenOrderTable = () => {
        if (!openOrders) {
            return null;
        }

        const rows = openOrders.map((openOrder) => (
            <Table.Tr
                key={openOrder.jobItemId}>
                <Table.Td>{moment(openOrder.orderDate).format('DD/MM/YY')}</Table.Td>
                <Table.Td>{openOrder.customer}</Table.Td>
                <Table.Td>{openOrder.customerPONumber}</Table.Td>
                <Table.Td>
                    <a href={null} onClick={(e) => {
                        history.push(`/job/${openOrder.jobId}`);
                        e.stopPropagation();
                    }} style={{
                        color: "#0066CC",
                        whiteSpace: 'nowrap',
                        textDecoration: "underline",
                        zIndex: "999",
                        cursor: 'pointer'
                    }}>{openOrder.jobNumber}</a>
                </Table.Td>
                <Table.Td>{openOrder.supplierPONumber}</Table.Td>
                <Table.Td>{openOrder.quantity}</Table.Td>
                <Table.Td>{openOrder.description}</Table.Td>
                <Table.Td>{openOrder.deliveryDate ? moment(openOrder.deliveryDate).format('DD/MM/YY') : ''}</Table.Td>
                <Table.Td>
                    <NumberFormatter
                        prefix="£"
                        thousandSeparator
                        value={openOrder.cost}/>
                </Table.Td>
                <Table.Td>
                    <Center>
                        <Tooltip label="Edit order">
                            <i
                                className="fa-solid fa-pen-to-square"
                                style={{fontSize: '18px', cursor: 'pointer'}}
                                onClick={() => openEditOpenOrderModal(openOrder)}/>
                        </Tooltip>
                    </Center>
                </Table.Td>
            </Table.Tr>
        ))

        return (
            <>
                <section>
                        <Table stickyHeader
                               striped
                               highlightOnHover
                               withTableBorder
                               withColumnBorders>
                            <Table.Thead>
                                <Table.Tr>
                                    <Table.Th>Order date</Table.Th>
                                    <Table.Th>Customer</Table.Th>
                                    <Table.Th>PO no</Table.Th>
                                    <Table.Th>Job no</Table.Th>
                                    <Table.Th>Supplier PO</Table.Th>
                                    <Table.Th>Qty</Table.Th>
                                    <Table.Th>Description</Table.Th>
                                    <Table.Th>Delivery date</Table.Th>
                                    <Table.Th>Value</Table.Th>
                                    <Table.Th>Actions</Table.Th>
                                </Table.Tr>
                            </Table.Thead>
                            <Table.Tbody>{rows}</Table.Tbody>
                        </Table>
                </section>
            </>
        )
    };

    return (
        <>
            <Box pos="relative">
                <LoadingOverlay visible={isLoading} zIndex={1000} overlayProps={{ radius: "sm", blur: 2 }} />

                <Header/>

                <OpenOrderTable/>

            </Box>
            
            <EditOpenOrderModal
                ref={editOpenOrderModalRef}
                onOpenOrderSaved={onOpenOrderSaved}
            />
        </>
    )
}

export default OpenOrdersPage;