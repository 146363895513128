import {forwardRef, useEffect, useImperativeHandle, useState} from "react";
import {useDisclosure} from "@mantine/hooks";
import {Button, Checkbox, Group, Modal, Select, Stack, TextInput} from "@mantine/core";
import API from 'API';

const EditThirdPartyModal = forwardRef(({onThirdPartySaved}, ref) => {
    const [opened, { open, close }] = useDisclosure(false);
    const [thirdParty, setThirdParty] = useState({ supplierPONumber: '', supplierId: null, status: null });
    const [suppliers, setSuppliers] = useState([]);

    useImperativeHandle(ref, () => {
        return {
            open(thirdPartyDto) {
                open();
                setThirdParty(thirdPartyDto);
            }
        }
    });

    useEffect(() => {
        async function getSuppliers() {
            let supplierData = await API.call('supplier/list');
            
            let suppliersList = supplierData.map((supplierData) => {
                return { value: supplierData.id.toString(), label: supplierData.name }
            });
            
            setSuppliers(suppliersList);
        }
        
        getSuppliers();
    }, [thirdParty]);

    const saveAndClose = async () => {
        await API.call('third-party/save', thirdParty);
        onThirdPartySaved(thirdParty);
        close();
    }
    
    if (thirdParty === undefined && suppliers.length === 0) {
        return null;
    }
 
    return (
        <>
            <Modal 
                opened={opened} 
                onClose={close}
                centered
                withCloseButton={false}
                size="auto"
                overlayProps={{
                    backgroundOpacity: 0.55,
                    blur: 3,
                }}
            >
                <Stack>
                    <TextInput 
                        label="Supplier PO"
                        value={thirdParty.supplierPONumber}
                        onChange={(e) => setThirdParty({ ...thirdParty, supplierPONumber: e.target.value})}
                    />

                    <Select
                        label="Supplier"
                        value={thirdParty.supplierId ? thirdParty.supplierId.toString() : null}
                        data={suppliers}
                        searchable
                        onChange={(_value, option) => {
                            setThirdParty( {...thirdParty, supplierId: option.value, supplierName: option.label })
                        }}
                    />
                    
                    <Select
                        label="Status"
                        data={['AT PAINT', 'READY', 'ON HOLD', 'CANCELLED', 'PAID(COD)ACCOUNT']}
                        value={thirdParty.status ? thirdParty.status : ''}
                        onChange={(e) => setThirdParty({ ...thirdParty, status: e })}
                    />

                    <Checkbox
                        label="Third party"
                        labelPosition="left"
                        checked={thirdParty.isThirdParty}
                        onChange={(e) => setThirdParty({ ...thirdParty, isThirdParty: e.target.checked})}
                    />
                    
                    <Group justify="space-between">
                        <Button onClick={() => close()}>Close</Button>
                        <Button onClick={() => saveAndClose()}>Save and close</Button>
                    </Group>
                </Stack>
            </Modal>
        </>
    )
})

export default EditThirdPartyModal;