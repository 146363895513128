import React from 'react';
import { Link } from 'react-router-dom';

// Services
import API from 'API';

// Components
import SuperTable from 'components/common/SuperTable';
import Loader from 'components/common/Loader';

//-----------------------------------------------------------------

class ListPurchaseOrderDeliveries extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            purchaseOrderDeliveries: []
        };
    }

    componentDidMount() {
        this.load();
    }

    componentDidUpdate(oldProps) {
        if (this.props.page != oldProps.page) {
            this.load();
        }
    }

    async load() {
        this.setState({ isLoading: true });
        const purchaseOrderDeliveries = await API.call('purchase-order-delivery/list');
        this.setState({
            isLoading: false,
            purchaseOrderDeliveries
        });
    }

    open(id) {
        this.props.history.push(`/purchase-order-delivery/${id}`);
    }

    //----------------------------------------------------------------------

    render() {
        return (<>

            <section className="control-panel">

                <Link className="ms-auto btn btn-primary" to="/purchase-order-delivery/0">
                    New PO Delivery
                </Link>

            </section>

            <section>

                {this.renderTable()}

            </section>

        </>);
    }

    renderTable() {
        const {
            isLoading,
            purchaseOrderDeliveries
        } = this.state;

        if (isLoading) {
            return (<Loader />);
        }
        
        const cols = {
            reference: {
                label: 'Del. Note Number',
                className: 'ref-col'
            },
            purchaseOrderReference: {
                label: 'PO Number',
                className: 'purchase-order-ref-col',
                getValue: (colInfo, purchaseOrderDelivery) =>
                    (purchaseOrderDelivery.purchaseOrder ? purchaseOrderDelivery.purchaseOrder.reference : '')
            },
            supplierName: {
                label: 'Supplier',
                className: 'supplier-name-col',
                getValue: (colInfo, purchaseOrderDelivery) =>
                    (purchaseOrderDelivery.purchaseOrder && purchaseOrderDelivery.purchaseOrder.supplier ? purchaseOrderDelivery.purchaseOrder.supplier.name : '')
            }
        };
        
        return (
            <SuperTable
                className="purchase-order-deliveries-table table table-bordered mb-0"
                rows={purchaseOrderDeliveries}
                keyAccessor={purchaseOrderDelivery => purchaseOrderDelivery.id}
                cols={cols}
                onClick={(purchaseOrderDelivery, e) => this.open(purchaseOrderDelivery.id)}
                emptyText="No PO deliveries to show"
            />
        );
    }
}

export default ListPurchaseOrderDeliveries;