import App from 'App';

export default class API {
    static async call(url, data) {
        try {
            data = { ...data };
            const opt = {
                method: (data.method || 'POST'),
                headers: {
                    'Content-Type': 'application/json',
                    ...API.extraHeaders,
                    'x-version': App.VERSION
                }
            };
            if (data.method) {
                delete data.method;
            }
            if ((opt.method || '').toLowerCase() != 'get') {
                opt.body = JSON.stringify(data);
            }
            const response = await fetch('/api/' + url, opt);

            if (data && data.isDownload) {
                if (!data.fileName) {
                    const contentDisposition = response.headers.get('content-disposition');
                    if (contentDisposition) {
                        const match = contentDisposition.match(/filename="(.+?)"/);
                        if (match) {
                            data.fileName = match[1];
                        }
                    }
                }

                const blob = await response.blob();

                var a = document.createElement('a');
                a.style = 'display: none';
                document.body.appendChild(a);
                try {
                    a.href = window.URL.createObjectURL(blob);
                    a.download = data.fileName;
                    a.click();
                } finally {
                    setTimeout(() => {
                        window.URL.revokeObjectURL(blob);
                        a.remove();
                    }, 100);
                }
            } else {
                const text = await response.text();
                let responseObject = null;
                if (text.length > 0) {
                    responseObject = JSON.parse(text);
                }

                switch (response.status) {
                    case 200:
                        if (responseObject) {
                            if (responseObject.success) {
                                return responseObject.content;
                            } else {
                                throw responseObject.content; // Error message
                            }
                        } else {
                            return null;
                        }
                    case 400:
                    case 401:
                    case 403:
                    case 500:
                        if (responseObject) {
                            throw responseObject.content;
                        }
                        break;
                }
            }

        } catch (error) {
            throw error;
        }
    }
    
    // static async call(url, data) {
    //     try {
    //         data = { ...data };
    //         const opt = {
    //             method: (data.method || 'POST'),
    //             headers: {
    //                 'Content-Type': 'application/json'
    //             }
    //         };
    //         const ignoreAPIErrorHandler = !!data.ignoreAPIErrorHandler;
    //         if (data.method) {
    //             delete data.method;
    //         }
    //         if (data.ignoreAPIErrorHandler) {
    //             delete data.ignoreAPIErrorHandler;
    //         }
    //         if ((opt.method || '').toLowerCase() != 'get') {
    //             opt.body = JSON.stringify(data);
    //         }
    //
    //         if (data && data.isDownload) {
    //             if (!data.fileName) {
    //                 const contentDisposition = response.headers.get('content-disposition');
    //                 if (contentDisposition) {
    //                     const match = contentDisposition.match(/filename="(.+?)"/);
    //                     if (match) {
    //                         data.fileName = match[1];
    //                     }
    //                 }
    //             }
    //
    //             const blob = await response.blob();
    //
    //             var a = document.createElement('a');
    //             a.style = 'display: none';
    //             document.body.appendChild(a);
    //             try {
    //                 a.href = window.URL.createObjectURL(blob);
    //                 a.download = data.fileName;
    //                 a.click();
    //             } finally {
    //                 setTimeout(() => {
    //                     window.URL.revokeObjectURL(blob);
    //                     a.remove();
    //                 }, 100);
    //             }
    //         } else {
    //             const text = await response.text();
    //             let responseObject = null;
    //             if (text.length > 0) {
    //                 responseObject = JSON.parse(text);
    //             }
    //
    //             switch (response.status) {
    //                 case 200:
    //                     if (responseObject) {
    //                         if (responseObject.success) {
    //                             return responseObject.content;
    //                         } else {
    //                             throw responseObject.content; // Error message
    //                         }
    //                     } else {
    //                         return null;
    //                     }
    //                 case 400:
    //                 case 401:
    //                 case 403:
    //                 case 500:
    //                     if (responseObject) {
    //                         throw responseObject.content;
    //                     }
    //                     break;
    //             }
    //         }
    //        
    //         const response = await fetch('/api/' + url, opt);
    //         const text = await response.text();
    //         let responseObject = null;
    //         if (text.length > 0) {
    //             responseObject = JSON.parse(text);
    //         }
    //
    //         switch (response.status) {
    //             case 200:
    //                 if (responseObject) {
    //                     if (responseObject.success) {
    //                         return responseObject.content;
    //                     } else {
    //                         throw responseObject.content; // Error message
    //                     }
    //                 } else {
    //                     return null;
    //                 }
    //             case 400:
    //             case 401:
    //             case 403:
    //             case 500:
    //                 if (responseObject) {
    //                     throw responseObject.content;
    //                 }
    //                 break;
    //         }
    //
    //     } catch (error) {
    //         throw error;
    //     }
    // }

    static async postFile(url, file, data) {
        try {

            // Create form data object
            var formData = new FormData();
            formData.append('file', file);
            if (data) {
                for (var key in data) {
                    formData.append(key, data[key])
                }
            }

            // Do the upload
            const response = await fetch('/api/' + url, {
                method: 'POST',
                body: formData
            });
            const text = await response.text();
            if (text.length > 0) {
                const responseObject = JSON.parse(text);
                return responseObject;
            } else {
                // TODO handle differently?
                return null;
            }
        } catch (error) {
            console.log('API error: ', error);
            return {
                success: false,
                message: error
            };
        }

    }
}

//export default class BaseService {
    
//    static async callAPI(url, data) {
//        try {
//            const response = await fetch('/api/' + url, {
//                method: 'POST',
//                headers: {
//                    'Content-Type': 'application/json'
//                },
//                body: (data ? JSON.stringify(data) : null)
//            });
//            const text = await response.text();
//            if (response.status == 404) {
//                throw 'API CALL 404 (' + url + ')';
//            } else if (text.length > 0) {
//                const responseObject = JSON.parse(text);
//                if (responseObject.success) {
//                    return responseObject.content;
//                } else {
//                    throw responseObject.content; // Error message
//                }
//            } else {
//                return null;
//            }
//        } catch (error) {
//            throw error;
//        }
//    }

//    static async postFile(url, file, data) {
//        try {

//            // Create form data object
//            var formData = new FormData();
//            formData.append('file', file);
//            if (data) {
//                for (var key in data) {
//                    formData.append(key, data[key])
//                }
//            }

//            // Do the upload
//            const response = await fetch('/api/' + url, {
//                method: 'POST',
//                body: formData
//            });
//            const text = await response.text();
//            if (text.length > 0) {
//                const responseObject = JSON.parse(text);
//                return responseObject;
//            } else {
//                // TODO handle differently?
//                return null;
//            }
//        } catch (error) {
//            console.log('API error: ', error);
//            return {
//                success: false,
//                message: error
//            };
//        }

//    }

//    //static handleAJAXError(response) {
//    //    if (response.success) {
//    //        return true;
//    //    } else {

//    //    }
//    //}
//}