import React, {createRef, useEffect, useState} from 'react';
import API from 'API';
import moment from "moment";
import {
    Box,
    Button,
    Center,
    Flex,
    LoadingOverlay,
    NumberFormatter,
    Table,
    Tooltip
} from "@mantine/core";
import {useHistory} from "react-router-dom";
import EditOpenOrderModal from "./EditOpenOrderModal";
import {IconCheck} from "@tabler/icons-react";
import {notifications} from "@mantine/notifications";

const ThirdPartyPage = () => {
    const [thirdParties, setThirdParties] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isExportingToExcel, setIsExportingToExcel] = useState(false);

    const successIcon = <IconCheck style={{ width: '20px', height: '20px' }} />;

    const editOpenOrderModalRef = createRef();

    let history = useHistory();

    useEffect( () => {
        async function lordThirdParties() {
            const thirdPartiesList = await API.call('third-party/list');
            setThirdParties(thirdPartiesList);
        }
        lordThirdParties().then(() => setIsLoading(false));
    }, []);

    const openEditOpenOrderModal = (openOrder) => {
        editOpenOrderModalRef.current.open(openOrder);
    }

    const onThirdPartySaved = (thirdParty) => {
        let thirdPartiesCopy = [...thirdParties];

        let editedThirdParty = thirdPartiesCopy.find(o => o.jobItemId === thirdParty.jobItemId);

        editedThirdParty.supplierPONumber = thirdParty.supplierPONumber;
        editedThirdParty.supplierId = thirdParty.supplierId;
        editedThirdParty.supplierName = thirdParty.supplierName;
        editedThirdParty.status = thirdParty.status;
        editedThirdParty.isThirdParty = thirdParty.isThirdParty;
        
        if (!editedThirdParty.isThirdParty) {
            let editedThirdPartyIndex = thirdPartiesCopy.indexOf(editedThirdParty);

            thirdPartiesCopy.splice(editedThirdPartyIndex, 1);
        }

        setThirdParties(thirdPartiesCopy);
        
        notifications.show({
            title: 'Success',
            message: 'Your changes have been saved',
            icon: successIcon,
            color: 'teal'
        })
    }

    const printThirdParties = async () => {
        setIsExportingToExcel(true);
        
        const notificationId = notifications.show({
            title: 'Export to excel',
            message: 'Generating data...',
            loading: true,
            autoClose: false
        });
        
        try {
            await API.call('third-party/print', {
                isDownload: true
            })    
        } catch {
        } finally {
            notifications.hide(notificationId);
            setIsExportingToExcel(false);
        }
    }
    
    const Header = () => {
        return (
            <section>
                <Flex justify="flex-end" direction="row">
                    <button className="btn btn-primary" onClick={() => printThirdParties()}>Export to excel</button>
                </Flex>
            </section>
        );
    }

    const ThirdPartyTable = () => {
        if (!thirdParties) {
            return null;
        }

        const rows = thirdParties.map((thirdParty) => (
            <Table.Tr
                key={thirdParty.jobItemId}>
            <Table.Td>{moment(thirdParty.orderDate).format('DD/MM/YY')}</Table.Td>
                <Table.Td>{thirdParty.customer}</Table.Td>
                <Table.Td>{thirdParty.customerPONumber}</Table.Td>
                <Table.Td>
                    <a href={null} onClick={(e) => {
                        history.push(`/job/${thirdParty.jobId}`);
                        e.stopPropagation();
                    }} style={{color: "#0066CC", whiteSpace: 'nowrap', textDecoration: "underline", zIndex: "999", cursor: 'pointer'}}>{thirdParty.jobNumber}</a>
                </Table.Td>
                <Table.Td>{thirdParty.supplierPONumber}</Table.Td>
                <Table.Td>{thirdParty.quantity}</Table.Td>
                <Table.Td>{thirdParty.description}</Table.Td>
                <Table.Td>{thirdParty.deliveryDate ? moment(thirdParty.deliveryDate).format('DD/MM/YY') : ''}</Table.Td>
                <Table.Td>
                    <NumberFormatter
                        prefix="£"
                        thousandSeparator
                        value={thirdParty.cost} />
                </Table.Td>
                <Table.Td>{thirdParty.supplierName}</Table.Td>
                <Table.Td style={{ whiteSpace: 'nowrap' }}>{thirdParty.status}</Table.Td>
                <Table.Td>
                    <Center>
                        <Tooltip label="Edit order">
                            <i
                                className="fa-solid fa-pen-to-square"
                                style={{ fontSize: '18px', cursor: 'pointer'}}
                                onClick={() => openEditOpenOrderModal(thirdParty)}/>
                        </Tooltip>
                    </Center>
                </Table.Td>
            </Table.Tr>
        ))

        return (
            <>
                <section>
                    
                <Table stickyHeader
                       striped
                       stickyHeaderOffset={5}
                       highlightOnHover
                       withTableBorder
                       withColumnBorders>
                    <Table.Thead>
                        <Table.Tr>
                            <Table.Th>Order date</Table.Th>
                            <Table.Th>Customer</Table.Th>
                            <Table.Th>PO no</Table.Th>
                            <Table.Th>Job no
                            </Table.Th>
                            <Table.Th>Supplier PO</Table.Th>
                            <Table.Th>Qty</Table.Th>
                            <Table.Th>Description</Table.Th>
                            <Table.Th>Delivery date</Table.Th>
                            <Table.Th>Value</Table.Th>
                            <Table.Th>Supplier</Table.Th>
                            <Table.Th>Status</Table.Th>
                            <Table.Th>Actions</Table.Th>
                        </Table.Tr>
                    </Table.Thead>
                    <Table.Tbody>{rows}</Table.Tbody>
                </Table>

                </section>
            </>
        )
    }

    return (
        <>
            <Box pos="relative">
                <LoadingOverlay visible={isLoading} zIndex={1000} overlayProps={{ radius: "sm", blur: 2 }} />

                <Header />
                
                <ThirdPartyTable />
            </Box>

            <EditOpenOrderModal
                ref={editOpenOrderModalRef}
                onThirdPartySaved={onThirdPartySaved}
            />
        </>
    )
}

export default ThirdPartyPage;