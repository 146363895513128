import React from 'react';

class PrintDownloadButton extends React.Component {

    render() {
        const {
            className,
            text,
            onPrint,
            onDownload
        } = this.props;
        
        return (
            <div className={`dropdown ${className || ''}`}>
                <button className="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                    {text}
                </button>
                <ul className="dropdown-menu">
                    {onPrint &&
                        <li>
                            <a className="dropdown-item" href="#" onClick={e => { e.preventDefault(); onPrint(); }}>
                                Print
                            </a>
                        </li>
                    }
                    {onDownload &&
                        <li>
                            <a className="dropdown-item" href="#" onClick={e => { e.preventDefault(); onDownload(); }}>
                                Download
                            </a>
                        </li>
                    }
                </ul>
            </div>
        );
    }

}



export default PrintDownloadButton;