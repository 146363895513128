import React from 'react';
import { Link } from 'react-router-dom';

class PageList extends React.Component {

    render() {
        const {
            pages,
            page,
            getURL
        } = this.props;
        
        if (pages.length > 0) {
            return (
                <ul className="nav nav-pills">
                    {pages.map(p =>
                        <li key={p.id} className="nav-item">
                            <Link to={getURL(p.id)} className={`nav-link ${(p.id == page ? 'active' : '')}`}>
                                {p.label}
                            </Link>
                        </li>
                    )}
                </ul>
            );
        }
        
        return null;
    }

}

export default PageList;