import React from 'react';

// Services & Helpers
import FormHelper from 'helpers/FormHelper';
import API from 'API';

// Components
import Loader from 'components/common/Loader';

//-----------------------------------------------------------------

class EditCustomer extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            customer: null
        };
        this.form = new FormHelper({
            fields: {
                reference: {
                    label: 'Reference',
                    placeholder: 'Leave blank to set automatically'
                },
                name: {
                    label: 'Name',
                    autoFocus: true
                },
                contactTitle: {
                    label: 'Title'
                },
                contactFirstName: {
                    label: 'First Name'
                },
                contactLastName: {
                    label: 'Last Name'
                },
                contactPhone1: {
                    label: 'Primary Phone'
                },
                contactPhone2: {
                    label: 'Secondary Phone'
                },
                contactEmail1: {
                    label: 'Primary Email'
                },
                contactEmail2: {
                    label: 'Secondary Email'
                },
                'address.line1': {
                    label: 'Line 1'
                },
                'address.line2': {
                    label: 'Line 2'
                },
                'address.line3': {
                    label: 'Line 3'
                },
                'address.town': {
                    label: 'Town'
                },
                'address.county': {
                    label: 'County'
                },
                'address.postcode': {
                    label: 'Postcode'
                }
            },
            getValue: (fieldName) => this.getFieldValue(fieldName),
            setValue: (fieldName, value) => this.updateFields({ [fieldName]: value })
        });
    }

    componentDidMount() {
        this.load();
    }

    componentDidUpdate(oldProps) {
        if (this.props.id != oldProps.id) {
            this.load();
        }
    }

    async load() {
        let customer;

        if (this.props.id) {
            this.setState({ isLoading: true });
            customer = await API.call(`customer/get/${this.props.id}`);
        } else {
            customer = {
                address: {}
            };
        }
        this.setState({
            isLoading: false,
            customer
        });
    }

    getFieldValue(fieldName) {
        let entity = 'customer';
        const fieldNameParts = fieldName.split('.');
        if (fieldNameParts.length > 1) {
            entity = fieldNameParts[0];
            fieldName = fieldNameParts[1];
        }
        switch (entity) {
            case 'customer':
                return this.state.customer[fieldName];
            case 'address':
                return this.state.customer.address[fieldName];
        }
    }

    async save() {
        if (this.state.isLoading) {
            return;
        }
        const { customer } = this.state;
        this.setState({ isLoading: true });
        const id = await API.call('customer/save', customer);
        if (id && !parseInt(this.props.id)) {
            this.props.history.replace('/customer/' + id);
        } else {
            this.load();
        }
    }   

    updateFields(fields) {
        const customer = { ...this.state.customer };
        
        for (let fieldName in fields) {
            const fieldNameParts = fieldName.split('.');
            const value = fields[fieldName];
            let entity = 'customer';
            if (fieldNameParts.length > 1) {
                entity = fieldNameParts[0];
                fieldName = fieldNameParts[1];
            }
            switch (entity) {
                case 'customer':
                    customer[fieldName] = value;
                    break;
                case 'address':
                    const address = { ...this.state.customer.address };
                    address[fieldName] = value;
                    customer.address = address;
                    break;
            }
        }
        this.setState({ customer });
    }

    //---------------------------------------------------------------------------------------------------------------

    render() {
        const {
            isLoading,
            customer
        } = this.state;

        if (isLoading) {
            return (<Loader />);
        }

        return (<>

            <form onSubmit={e => { e.preventDefault(); this.save() }}>

                <section className="control-panel sticky">

                    <h1>{customer.id ? customer.name : 'New Customer'}</h1>

                    <button type="submit" className="ms-auto btn btn-primary">
                        Save
                    </button>

                </section>

                <section>

                    <fieldset>

                        <h2>Basic Details</h2>

                        <div className="row">

                            <div className="col-md-6">

                                {this.form.render('name')}

                            </div>

                            <div className="col-md-6">

                                {this.form.render('reference')}

                            </div>

                        </div>

                        <h2 className="mt-3">Main Contact</h2>

                        <div className="row">

                            <div className="col-md-6">

                                {this.form.render('contactFirstName')}

                            </div>

                            <div className="col-md-6">

                                {this.form.render('contactLastName')}

                            </div>

                        </div>

                        <div className="row">

                            <div className="col-md-6">

                                {this.form.render('contactPhone1')}

                            </div>

                            <div className="col-md-6">

                                {this.form.render('contactPhone2')}

                            </div>

                        </div>

                        <div className="row">

                            <div className="col-md-6">

                                {this.form.render('contactEmail1')}

                            </div>

                            <div className="col-md-6">

                                {this.form.render('contactEmail2')}

                            </div>

                        </div>

                        <div className="row">

                            <div className="col-md-6">

                                <h2 className="mt-3">Trading Address</h2>

                                {this.form.render([
                                    'address.line1',
                                    'address.line2',
                                    'address.line3',
                                    'address.town',
                                    'address.county',
                                    'address.postcode'
                                ])}

                            </div>

                            <div className="col-md-6">
                                                            
                            </div>

                        </div>

                    </fieldset>

                </section>

            </form>

        </>);
    }

}

export default EditCustomer;