import React from 'react';
import moment from 'moment';

// Services & Helpers
import FormHelper from 'helpers/FormHelper';
import API from 'API';
import Print from 'Print';

// Components
import Loader from 'components/common/Loader';
import SearchBox from 'components/common/SearchBox';
import PrintDownloadButton from 'components/common/PrintDownloadButton';
import ItemsTable from 'components/ItemsTable';

//-----------------------------------------------------------------

class EditJobDelivery extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            jobDelivery: null
        };
        this.form = new FormHelper({
            fields: {
                reference: {
                    label: 'Delivery Note Number',
                    placeholder: 'Leave blank to set automatically'
                },
                date: {
                    label: 'Date',
                    type: 'date'
                }
            },
            getValue: (fieldName) => this.getFieldValue(fieldName),
            setValue: (fieldName, value) => this.updateFields({ [fieldName]: value })
        });
    }

    componentDidMount() {
        this.load();
    }

    componentDidUpdate(oldProps) {
        if (this.props.id != oldProps.id) {
            this.load();
        }
    }

    async load() {
        let jobDelivery;

        if (parseInt(this.props.id) || 0) {
            this.setState({ isLoading: true });
            jobDelivery = await API.call(`job-delivery/get/${this.props.id}`);
        } else {
            jobDelivery = {
                jobID: (parseInt(this.props.jobID) || null),
                date: moment(moment().utc().startOf('day')).local().toDate(),
                items: []
            };
        }
        this.setState({
            isLoading: false,
            isLoadingJob: !!jobDelivery.jobID,
            jobDelivery
        }, () => {
            this.loadJob();
        });
    }

    getFieldValue(fieldName) {
        let entity = 'jobDelivery';
        const fieldNameParts = fieldName.split('.');
        if (fieldNameParts.length > 1) {
            entity = fieldNameParts[0];
            fieldName = fieldNameParts[1];
        }
        switch (entity) {
            case 'jobDelivery':
                return this.state.jobDelivery[fieldName];
        }
    }

    async save() {
        return new Promise(async (resolve) => {
            if (this.state.isLoading) {
                return;
            }
            const { jobDelivery } = this.state;
            console.log(JSON.stringify(jobDelivery));
            this.setState({ isLoading: true });
            try {
                const id = await API.call('job-delivery/save', jobDelivery);

                if (id && !parseInt(this.props.id)) {
                    this.props.history.replace('/job-delivery/' + id);
                } else {
                    await this.load();
                }

                resolve(id);
            } catch (ex) {
                alert(ex);
                this.setState({ isLoading: false });
            }
        });
    }

    async saveAndRender(isInline) {
        const jobDeliveryID = await this.save();
        this.setState({ isLoading: true });
        await Print.url(`/api/render/jobdelivery/pdf`, { jobDeliveryID, isInline });
        this.setState({
            isLoading: false
        });
    }

    async saveAndReturn() {
        await this.save();
        this.props.history.push(`/job/${this.state.jobDelivery.jobID}`);
    }

    updateFields(fields) {
        const jobDelivery = { ...this.state.jobDelivery };
        let loadJob = false;

        for (let fieldName in fields) {
            let value = fields[fieldName];
            if(fieldName === 'deliveryDate' || fieldName === 'date') {
                value = moment(value).locale('en-gb').format('YYYY-MM-DD');
            }
            jobDelivery[fieldName] = value;
            if (fieldName == 'jobID') {
                loadJob = true;
            }
        }
        this.setState({
            jobDelivery
        }, () => {
            if (loadJob) {
                this.loadJob();
            }
        });
    }

    async loadJob() {
        const { jobDelivery } = this.state;
        if (jobDelivery.jobID) {
            this.setState({ isLoadingJob: true });
            const job = await API.call('job/get/' + jobDelivery.jobID);
            const fields = { job };
            if (!jobDelivery.items || jobDelivery.items.length == 0) {
                fields.items = job.items;
            }
            this.updateFields(fields);
            this.setState({ isLoadingJob: false });
        } else {
            this.updateFields({ job: null });
        }
    }

    updateItem(index, values) {
        const items = [...this.state.jobDelivery.items];
        const item = { ...items[index] };
        for (let fieldName in values) {
            const value = values[fieldName];
            item[fieldName] = value;
        }
        items[index] = item;
        this.updateFields({ items });
    }

    async confirmDelete() {
        const confirm = window.confirm('Are you sure you want to delete this delivery note?');
        if (confirm) {
            await this.delete();
        }
    }

    async delete() {
        const { isLoading, jobDelivery } = this.state;
        if (isLoading) {
            return;
        }
        this.setState({ isLoading: true });
        try {
            await API.call('job-delivery/delete/' + jobDelivery.id);
            this.props.history.replace(`/job/${jobDelivery.jobID}`);
        } catch (error) {
            alert(error);
            this.setState({ isLoading: false });
        }
    }

    //---------------------------------------------------------------------------------------------------------------

    render() {
        const {
            isLoading,
            jobDelivery
        } = this.state;

        if (isLoading) {
            return (<Loader />);
        }

        return (<>

            <form onSubmit={e => { e.preventDefault(); this.saveAndReturn() }}>

                <section className="control-panel sticky">

                    <h1>{this.renderTitle()}</h1>

                    <div className="button-panel ms-auto">

                        <button type="submit" className="btn btn-primary">
                            Save
                        </button>

                        <PrintDownloadButton
                            text="Delivery Note"
                            onPrint={() => this.saveAndRender(true)}
                            onDownload={() => this.saveAndRender(false)}
                        />
                        
                    </div>

                </section>

                <section>

                    <fieldset>

                        <h2>Basic Details</h2>

                        <div className="row">

                            <div className="col-md-6">

                                <div className="form-group">
                                    <label className="form-label">Job</label>
                                    {this.renderJob()}
                                </div>

                            </div>

                            <div className="col-md-3">

                                {this.form.render('reference')}

                            </div>

                            <div className="col-md-3">

                                {this.form.render('date')}

                            </div>

                        </div>

                    </fieldset>

                    {!!jobDelivery.jobID && <>
                        <h2 className="mt-5">Items dispatched</h2>

                        <ItemsTable
                            mode="JobDelivery"
                            items={jobDelivery.items}
                            onChange={(index, values) => this.updateItem(index, values)}
                        />
                    </>}

                </section>

                {!!jobDelivery.id &&
                    <section className="control-panel">

                        <button type="button" className="btn btn-danger ms-auto" onClick={() => this.confirmDelete()}>
                            <span className="fa fa-times" />{' '}
                            Delete
                        </button>

                    </section>
                }

            </form>

        </>);
    }

    renderTitle() {
        const { jobDelivery } = this.state;
        let title = '';
        if (!jobDelivery.id) {
            title += 'New Delivery Note ';
        } else {
            title += 'Delivery Note ';
        }
        if (jobDelivery.reference) {
            title += `${jobDelivery.reference} `;
        }
        if (jobDelivery.job) {
            title += 'for ' + jobDelivery.job.reference;
        }
        return title;
    }

    renderJob() {
        const {
            isLoadingJob,
            jobDelivery
        } = this.state;
        if (jobDelivery.job) {
            return (
                <p className="form-control-plaintext">
                    {jobDelivery.job.reference}
                    {jobDelivery.job.customer && <>
                        {' '}({jobDelivery.job.customer.name})
                    </>}
                    <button className="btn btn-sm btn-primary fa fa-search ms-2" title="View job" onClick={() => this.props.history.push(`/job/${jobDelivery.jobID}`)} />
                    <button className="btn btn-sm btn-danger fa fa-times ms-2" title="Select a different job" onClick={() => this.updateFields({ jobID: null })} />
                </p>
            );
        }
        if (isLoadingJob) {
            return (
                <p className="form-control-plaintext">Loading...</p>
            );
        }
        return (
            <SearchBox
                autoFocus={!jobDelivery.jobDeliveryID}
                className="form-control"
                placeholder="Search..."
                minLength={2}
                search={async (query, setResults, dataObj) => {
                    dataObj.nonce = `${Math.random()}`;
                    const response = await API.call('search', { query, nonce: dataObj.nonce, types: [ 'Job' ] });
                    if (response.nonce == dataObj.nonce) {
                        setResults(response.results);
                    }
                }}
                onClickResult={result => this.updateFields({ jobID: result.id })}
            />
        );
    }
}

export default EditJobDelivery;