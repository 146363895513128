import React from 'react';

// Services & Helpers
import FormHelper from 'helpers/FormHelper';
import API from 'API';

// Components
import Loader from 'components/common/Loader';

//-----------------------------------------------------------------

class EditProduct extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            product: null
        };
        this.form = new FormHelper({
            fields: {
                code: {
                    label: 'Code',
                    autoFocus: true
                },
                name: {
                    label: 'Name'
                },
                description: {
                    label: 'Description',
                    type: 'expanding-text'
                },
                unitPrice: {
                    label: 'Unit Price',
                    type: 'currency'
                },
                unitType: {
                    label: 'Unit Type',
                    type: 'single-select',
                    emptyText: '(Unspecified)',
                    getOptions: () => [
                        { value: 'Each', text: 'Each' },
                        { value: 'Sets', text: 'Sets' },
                        { value: 'Pairs', text: 'Pairs' }
                    ]
                }
            },
            getValue: (fieldName) => this.state.product[fieldName],
            setValue: (fieldName, value) => this.updateFields({ [fieldName]: value })
        });
    }

    componentDidMount() {
        this.load();
    }

    componentDidUpdate(oldProps) {
        if (this.props.id != oldProps.id) {
            this.load();
        }
    }

    async load() {
        let product;

        if (this.props.id) {
            this.setState({ isLoading: true });
            product = await API.call(`product/get/${this.props.id}`);
        } else {
            product = {
                address: {}
            };
        }
        this.setState({
            isLoading: false,
            product
        });
    }

    async save() {
        if (this.state.isLoading) {
            return;
        }
        const { product } = this.state;
        this.setState({ isLoading: true });
        const id = await API.call('product/save', product);
        if (id && !parseInt(this.props.id)) {
            this.props.history.replace('/product/' + id);
        } else {
            this.load();
        }
    }   

    updateFields(fields) {
        const product = { ...this.state.product };
        for (let fieldName in fields) {
            const value = fields[fieldName];
            product[fieldName] = value;
        }
        this.setState({ product });
    }

    //---------------------------------------------------------------------------------------------------------------

    render() {
        const {
            isLoading,
            product
        } = this.state;

        if (isLoading) {
            return (<Loader />);
        }

        return (<>

            <form onSubmit={e => { e.preventDefault(); this.save() }}>

                <section className="control-panel sticky">

                    <h1>{product.id ? product.name : 'New Product'}</h1>

                    <button type="submit" className="ms-auto btn btn-primary">
                        Save
                    </button>

                </section>

                <section>

                    <fieldset>

                        <h2>Basic Details</h2>

                        <div className="row">

                            <div className="col-md-6">

                                {this.form.render('code')}

                            </div>

                            <div className="col-md-6">

                                {this.form.render('name')}

                            </div>

                        </div>

                        <div className="row">

                            <div className="col-md-6">

                                {this.form.render('unitPrice')}

                            </div>

                            <div className="col-md-6">

                                {this.form.render('unitType')}

                            </div>

                        </div>

                        {this.form.render('description')}

                    </fieldset>

                </section>

            </form>

        </>);
    }

}

export default EditProduct;