import React, { useState } from 'react';
import { Link } from 'react-router-dom';

// Services
import API from 'API';

// Components
import SuperTable from 'components/common/SuperTable';
import Loader from 'components/common/Loader';

//-----------------------------------------------------------------

class ListJobDeliveries extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            jobDeliveries: [],
            deleted: false
        };
    }

    componentDidMount() {
        this.load();
    }

    componentDidUpdate(oldProps, prevState) {
        if (this.props.page !== oldProps.page) {
            this.load();
        }
        if (prevState.deleted !== this.state.deleted) {
            this.load();
        }
    }

    async load() {
        this.setState({ isLoading: true });
        const jobDeliveries = await API.call('job-delivery/list', {isDeleted: this.state.deleted});
        this.setState({
            isLoading: false,
            jobDeliveries,
            deleted: this.state.deleted,
        });
    }

    open(id) {
        this.props.history.push(`/job-delivery/${id}`);
    }

    //----------------------------------------------------------------------

    render() {
        return (<>

            <section className="control-panel">

                <Link className="ms-auto btn btn-primary" to="/job-delivery/0">
                    New Delivery Note
                </Link>

            </section>

            <section>

                {this.renderTable()}

            </section>

        </>);
    }

    renderTable() {
        const {
            isLoading,
            jobDeliveries
        } = this.state;

        if (isLoading) {
            return (<Loader />);
        }

        const handleFilter = (e) => {
            this.setState({ deleted: e.target.checked });
        }

        const cols = {
            reference: {
                label: 'Del. Note Number',
                className: 'ref-col'
            },
            jobReference: {
                label: 'Job Number',
                className: 'job-ref-col',
                getValue: (colInfo, jobDelivery) => (jobDelivery.job ? jobDelivery.job.reference : '')
            },
            customerName: {
                label: 'Customer',
                className: 'customer-name-col',
                getValue: (colInfo, jobDelivery) => (jobDelivery.job && jobDelivery.job.customer ? jobDelivery.job.customer.name : '')
            }
        };

        return (
            <>

                <div className="form-check">
                    <input className="form-check-input" type="checkbox" value="" id="chbDeleted" onChange={handleFilter} checked={this.state.deleted}/>
                    <label className="form-check-label" htmlFor="chbDeleted">
                        Show Deleted
                    </label>
                </div>
                <br />
                <SuperTable
                    className="job-deliveries-table table table-bordered mb-0"
                    rows={jobDeliveries}
                    keyAccessor={jobDelivery => jobDelivery.id}
                    cols={cols}
                    onClick={(jobDelivery, e) => this.open(jobDelivery.id)}
                    emptyText="No delivery notes to show"
                />
            </>
        );
    }
}

export default ListJobDeliveries;