import React from 'react';
import moment from 'moment';

// Services & Helpers
import FormHelper from 'helpers/FormHelper';
import API from 'API';

// Components
import Loader from 'components/common/Loader';
import SearchBox from 'components/common/SearchBox';
import ItemsTable from 'components/ItemsTable';

//-----------------------------------------------------------------

class EditPurchaseOrderDelivery extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            purchaseOrderDelivery: null
        };
        this.form = new FormHelper({
            fields: {
                reference: {
                    label: 'Delivery Note Number'
                },
                date: {
                    label: 'Date',
                    type: 'date'
                }
            },
            getValue: (fieldName) => this.getFieldValue(fieldName),
            setValue: (fieldName, value) => this.updateFields({ [fieldName]: value })
        });
    }

    componentDidMount() {
        this.load();
    }

    componentDidUpdate(oldProps) {
        if (this.props.id != oldProps.id) {
            this.load();
        }
    }

    async load() {
        return new Promise(async (resolve, reject) => {
            let purchaseOrderDelivery;

            if (parseInt(this.props.id) || 0) {
                this.setState({ isLoading: true });
                purchaseOrderDelivery = await API.call(`purchase-order-delivery/get/${this.props.id}`);
            } else {
                purchaseOrderDelivery = {
                    purchaseOrderID: (parseInt(this.props.purchaseOrderID) || null),
                    date: moment(moment().utc().startOf('day')).local().toDate(),
                    items: []
                };
            }
            this.setState({
                isLoading: false,
                isLoadingPurchaseOrder: !!purchaseOrderDelivery.purchaseOrderID,
                purchaseOrderDelivery
            }, () => {
                this.loadPurchaseOrder();
                resolve();
            });
        });
    }

    getFieldValue(fieldName) {
        let entity = 'purchaseOrderDelivery';
        const fieldNameParts = fieldName.split('.');
        if (fieldNameParts.length > 1) {
            entity = fieldNameParts[0];
            fieldName = fieldNameParts[1];
        }
        switch (entity) {
            case 'purchaseOrderDelivery':
                return this.state.purchaseOrderDelivery[fieldName];
        }
    }

    async save() {
        return new Promise(async (resolve) => {
            if (this.state.isLoading) {
                return;
            }
            const { purchaseOrderDelivery } = this.state;
            this.setState({ isLoading: true });
            try {
                const id = await API.call('purchase-order-delivery/save', purchaseOrderDelivery);

                if (id && !parseInt(this.props.id)) {
                    this.props.history.replace('/purchase-order-delivery/' + id);
                } else {
                    await this.load();
                }

                resolve(id);
            } catch (ex) {
                alert(ex);
                this.setState({ isLoading: false });
            }
        });
    }

    async saveAndReturn() {
        await this.save();
        this.props.history.push(`/purchase-order/${this.state.purchaseOrderDelivery.purchaseOrderID}`);
    }

    updateFields(fields) {
        const purchaseOrderDelivery = { ...this.state.purchaseOrderDelivery };
        let loadPurchaseOrder = false;
        
        for (let fieldName in fields) {
            const fieldNameParts = fieldName.split('.');
            let value = fields[fieldName];
            let entity = 'purchaseOrderDelivery';
            if (fieldNameParts.length > 1) {
                entity = fieldNameParts[0];
                fieldName = fieldNameParts[1];
            }

            if(fieldName === 'deliveryDate' || fieldName === 'date') {
                value = moment(value).locale('en-gb').format('YYYY-MM-DD');
            }

            switch (entity) {
                case 'purchaseOrderDelivery':
                    purchaseOrderDelivery[fieldName] = value;

                    switch (fieldName) {
                        case 'purchaseOrderID':
                            loadPurchaseOrder = true;
                            break;
                    }

                    break;
            }
        }
        this.setState({
            purchaseOrderDelivery
        }, () => {
            if (loadPurchaseOrder) {
                this.loadPurchaseOrder();
            }
        });
    }

    async loadPurchaseOrder() {
        const { purchaseOrderDelivery } = this.state;
        if (purchaseOrderDelivery.purchaseOrderID) {
            this.setState({ isLoadingPurchaseOrder: true });
            const purchaseOrder = await API.call('purchase-order/get/' + purchaseOrderDelivery.purchaseOrderID);
            const fields = { purchaseOrder };
            if (!purchaseOrderDelivery.items || purchaseOrderDelivery.items.length == 0) {
                fields.items = [...purchaseOrder.items];
            }
            this.updateFields(fields);
            this.setState({ isLoadingPurchaseOrder: false });
        } else {
            this.updateFields({ purchaseOrder: null });
        }
    }

    updateItem(index, values) {
        const items = [...this.state.purchaseOrderDelivery.items];
        const item = { ...items[index] };
        for (let fieldName in values) {
            const value = values[fieldName];
            item[fieldName] = value;
        }
        items[index] = item;
        this.updateFields({ items });
    }

    //---------------------------------------------------------------------------------------------------------------

    render() {
        const {
            isLoading,
            purchaseOrderDelivery
        } = this.state;

        if (isLoading) {
            return (<Loader />);
        }

        return (<>

            <form onSubmit={e => { e.preventDefault(); this.saveAndReturn() }}>

                <section className="control-panel sticky">

                    <h1>{this.renderTitle()}</h1>

                    <div className="button-panel ms-auto">

                        <button type="submit" className="btn btn-primary">
                            Save
                        </button>
                        
                    </div>

                </section>

                <section>

                    <fieldset>

                        <h2>Basic Details</h2>

                        <div className="row">

                            <div className="col-md-6">

                                <div className="form-group">
                                    <label className="form-label">Purchase Order</label>
                                    {this.renderPurchaseOrder()}
                                </div>

                            </div>

                            <div className="col-md-3">

                                {this.form.render('reference')}

                            </div>

                            <div className="col-md-3">

                                {this.form.render('date')}

                            </div>

                        </div>

                    </fieldset>

                    {!!purchaseOrderDelivery.purchaseOrderID && <>
                        <h2 className="mt-5">Items delivered</h2>

                        <ItemsTable
                            mode="PurchaseOrderDelivery"
                            items={purchaseOrderDelivery.items}
                            onChange={(index, values) => this.updateItem(index, values)}
                        />
                    </>}

                </section>

            </form>

        </>);
    }

    renderTitle() {
        const { purchaseOrderDelivery } = this.state;
        let title = '';
        if (!purchaseOrderDelivery.id) {
            title += 'New PO Delivery ';
        } else {
            title += 'Delivery ';
        }
        if (purchaseOrderDelivery.reference) {
            title += `${purchaseOrderDelivery.reference} `;
        }
        if (purchaseOrderDelivery.purchaseOrder) {
            title += 'for ' + purchaseOrderDelivery.purchaseOrder.reference;
        }
        return title;
    }

    renderPurchaseOrder() {
        const {
            isLoadingPurchaseOrder,
            purchaseOrderDelivery
        } = this.state;
        if (purchaseOrderDelivery.purchaseOrder) {
            return (
                <p className="form-control-plaintext">
                    {purchaseOrderDelivery.purchaseOrder.reference}
                    {purchaseOrderDelivery.purchaseOrder.supplier && <>
                        {' '}({purchaseOrderDelivery.purchaseOrder.supplier.name})
                    </>}
                    <button className="btn btn-sm btn-primary fa fa-search ms-2" title="View purchase order" onClick={() => this.props.history.push(`/purchase-order/${purchaseOrderDelivery.purchaseOrderID}`)} />
                    <button className="btn btn-sm btn-danger fa fa-times ms-2" title="Select a different purchase order" onClick={() => this.updateFields({ purchaseOrderID: null })} />
                </p>
            );
        }
        if (isLoadingPurchaseOrder) {
            return (
                <p className="form-control-plaintext">Loading...</p>
            );
        }
        return (
            <SearchBox
                autoFocus={!purchaseOrderDelivery.purchaseOrderDeliveryID}
                className="form-control"
                placeholder="Search..."
                minLength={2}
                search={async (query, setResults, dataObj) => {
                    dataObj.nonce = `${Math.random()}`;
                    const response = await API.call('search', { query, nonce: dataObj.nonce, types: [ 'PurchaseOrder' ] });
                    if (response.nonce == dataObj.nonce) {
                        setResults(response.results);
                    }
                }}
                onClickResult={result => this.updateFields({ purchaseOrderID: result.id })}
            />
        );
    }
}

export default EditPurchaseOrderDelivery;