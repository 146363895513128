import React from 'react';
import { Link } from 'react-router-dom';

// Services
import API from 'API';

// Components
import SuperTable from 'components/common/SuperTable';
import Loader from 'components/common/Loader';
import PageList from 'components/common/PageList';

//-----------------------------------------------------------------

class ListCustomers extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoadingPages: true,
            customers: [],
            pages: []
        };
    }
    
    componentDidMount() {
        this.loadPages();
    }

    componentDidUpdate(oldProps) {
        if (this.props.page != oldProps.page) {
            this.load();
        }
    }

    async loadPages() {
        // Load pages
        this.setState({ isLoadingPages: true });
        const pages = (await API.call('customer/list-pages')) || [];

        // Update UI
        this.setState({
            isLoadingPages: false,
            pages
        });

        // Load first page
        if (this.props.page) {
            this.load();
        }
        else if (pages.length > 0) {
            this.props.history.replace(`/customers/${pages[0].id}`);
        }
    }

    async load() {
        this.setState({ isLoading: true });
        const customers = (await API.call('customer/list/' + this.props.page)) || [];
        this.setState({
            isLoading: false,
            customers
        });
    }

    open(id) {
        this.props.history.push(`/customer/${id}`);
    }

    //----------------------------------------------------------------------

    render() {
        const {
            pages
        } = this.state;
        const { page } = this.props;

        return (<>

            <section className="control-panel">
                
                <PageList
                    pages={pages}
                    page={page}
                    getURL={(id) => `/customers/${id}`}
                />

                <Link className="ms-auto btn btn-primary" to="/customer">
                    New customer
                </Link>

            </section>

            <section>

                {this.renderTable()}

            </section>

        </>);
    }

    renderTable() {
        const {
            isLoading,
            customers
        } = this.state;
        const { page } = this.props;

        if (isLoading) {
            return (<Loader />);
        }

        return (
            <SuperTable
                className="customers-table table table-bordered mb-0"
                rows={customers}
                keyAccessor={customer => customer.id}
                cols={{
                    reference: {
                        label: 'Customer Number',
                        className: 'ref-col'
                    },
                    name: {
                        label: 'Name'
                    }
                }}
                onClick={(customer, e) => this.open(customer.id)}
                emptyText={page ? 'No customers to show' : 'Please select a letter'}
            />
        );
    }
}

export default ListCustomers;