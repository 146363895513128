import React from 'react';
import { Link } from 'react-router-dom';

// Services
import API from 'API';

// Components
import SuperTable from 'components/common/SuperTable';
import Loader from 'components/common/Loader';
import PageList from 'components/common/PageList';

//-----------------------------------------------------------------

class ListPurchaseOrders extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            purchaseOrders: [],
            pages: [
                { id: 'open', label: 'Open' },
                { id: 'closed', label: 'Closed' }
            ]
        };
    }

    componentDidMount() {
        this.load();
    }

    componentDidUpdate(oldProps) {
        if (this.props.page != oldProps.page) {
            this.load();
        }
    }

    async load() {
        const { page } = this.props;
        this.setState({ isLoading: true });
        const purchaseOrders = (await API.call('purchase-order/list', { status: page })) || [];
        this.setState({
            isLoading: false,
            purchaseOrders
        });
    }

    open(id) {
        this.props.history.push(`/purchase-order/${id}`);
    }

    //----------------------------------------------------------------------

    render() {
        const {
            pages
        } = this.state;
        const { page } = this.props;

        return (<>

            <section className="control-panel">

                <PageList
                    pages={pages}
                    page={page}
                    getURL={(id) => `/purchase-orders/${id}`}
                />

                <Link className="ms-auto btn btn-primary" to="/purchase-order/0">
                    New Purchase Order
                </Link>

            </section>

            <section>

                {this.renderTable()}

            </section>

        </>);
    }

    renderTable() {
        const {
            isLoading,
            purchaseOrders
        } = this.state;
        const { page } = this.props;

        if (isLoading) {
            return (<Loader />);
        }
        
        return (
            <SuperTable
                className="purchase-orders-table table table-bordered mb-0"
                rows={purchaseOrders}
                keyAccessor={purchaseOrder => purchaseOrder.id}
                cols={{
                    reference: {
                        label: 'PO Number',
                        className: 'ref-col'
                    },
                    supplierName: {
                        label: 'Supplier',
                        className: 'supplier-name-col',
                        getValue: (colInfo, purchaseOrder) => (purchaseOrder.supplier ? purchaseOrder.supplier.name : '')
                    },
                    total: {
                        label: 'Total',
                        type: 'currency',
                        className: 'total-col'
                    }
                }}
                onClick={(purchaseOrder, e) => this.open(purchaseOrder.id)}
                emptyText={page ? `No ${(page || '').toLowerCase()} purchase orders to show` : 'Please select a page'}
            />
        );
    }
}

export default ListPurchaseOrders;