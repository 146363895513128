import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

// Components
import SideNav      from 'components/layout/SideNav';
import TopNav       from 'components/layout/TopNav';

//-------------------------------------------------------------------------------------------------------------------

class Layout extends Component {

    render() {
        return (<>
            
            <SideNav {...this.props} />

            <main>

                {/*
                <TopNav {...this.props} />
                */}

                <div className="main-content">

                    {this.props.children}

                </div>

            </main>

        </>);
    }
}

export default withRouter(Layout);