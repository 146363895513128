export default class TextHelpers {

    static DaysOfWeek = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

    static formatName(firstName, lastName) {
        var nameParts = [firstName, lastName].filter(np => !!((np || '').trim()));
        return nameParts.join(' ');
    }
   
    static simplifySearchString(str) {
        return (str || '').toLowerCase().replace(/[^a-z0-9 -'&@]/, '').trim();
    }

    static formatPercentage(percentage, numDP) {
        if (typeof (numDP) == 'undefined') {
            numDP = 2;
        }
        const div = Math.pow(10, numDP);
        let value = Math.round(percentage * 100 * div) / div;
        return value;
    }

    static formatCurrency(amount, opt) {
        const formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'GBP',
            ...opt
        });
        let str = formatter.format(amount);
        str = str.replace('.00', '');
        return str;
    }
    
    static getFriendlyName(type, value, quantity) {
        if (!value) {
            return '';
        }

        switch (type) {
            case 'SearchResult':
                switch (value) {
                    case 'SalesOrder': return 'Sales Order';
                    case 'JobDelivery': return 'Delivery Note';
                    case 'PurchaseOrder': return 'Purchase Order';
                    case 'PurchaseOrderDelivery': return 'PO Delivery';
                }
                break;
            case 'JobStatus':
                switch (value) {
                    case 'SalesOrder': return 'Sales Order';
                }
                break;
            case 'UnitType':
                switch (value) {
                    case 'PerSet': return 'Per Set';
                }
                break;
            case 'QuantityType':
                switch (value) {
                    case 'Sets': return 'Set' + (quantity == 1 ? '' : 's');
                    case 'Pairs': return 'Pair' + (quantity == 1 ? '' : 's');
                }
                break;
        }
        return value;
    }

    static getSearchResultIcon(type) {
        switch (type) {
            case 'Customer': return 'fa-solid fa-person';
            case 'Job': return 'fa-solid fa-screwdriver-wrench';
            case 'JobDelivery': return 'fa-solid fa-truck';
            case 'Supplier': return 'fa-solid fa-industry';
            case 'PurchaseOrder': return 'fa-solid fa-dolly';
            case 'PurchaseOrderDelivery': return 'fa-solid fa-truck';
        }
        return null;
    }

    static formatDuration(mins, interval) {
        mins = Math.ceil(mins || 0);
        if (typeof (interval) == 'undefined') {
            interval = 15;
        }
        const parts = [];
        mins = Math.floor(mins * interval) / interval;
        const hours = Math.floor(mins / 60);
        if (hours > 0) {
            parts.push(hours + 'hr');
            mins -= hours * 60;
        }
        if (mins > 0) {
            parts.push(mins + 'min');
        }
        return parts.join(' ');
    }

    static buildURL(url, queryString) {
        let isFirstQS = true;
        if (queryString) {
            for (var key in queryString) {
                let value = queryString[key];
                if (value) {
                    url += (isFirstQS ? '?' : '&') + key + '=' + encodeURIComponent(value);
                    isFirstQS = false;
                }
            }
        }
        return url;
    }

    static formatAddress(address, separator) {
        if (address == null) return '';
        if (typeof (separator) == 'undefined') {
            separator = ', ';
        }
        const result = [
            address.line1,
            address.line2,
            address.line3,
            address.town,
            address.postcode
        ].map(a => (a || '').trim())
            .filter(a => a)
            .join(separator);
        return result;
    }

    static escapeHTML(html) {
        html = html.replace(/&/g, '&amp;');
        html = html.replace(/>/g, '&gt;');
        html = html.replace(/</g, '&lt;');
        return html;
    }

    static escapeJSString(str) {
        str = str || '';
        str = str.replace(/'/g, '\\\'');
        str = str.replace(/\r/g, '\\r');
        str = str.replace(/\n/g, '\\n');
        return str;
    }

    static escapeAttribute(str) {
        str = str || '';
        str = str.replace(/"/g, '&quot;');
        str = str.replace(/\\/g, '\\\\');
        return str;
    }

}

window.TextHelpers = TextHelpers;
