// Libs
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';

// Components
import App from 'App';

// JS
import "bootstrap/dist/js/bootstrap.min.js";

// CSS
import '@mantine/core/styles.css';
import 'bootstrap/dist/css/bootstrap.css';
import '@fortawesome/fontawesome-free/css/all.css';
import './scss/main.scss'
import {MantineProvider} from "@mantine/core";
import '@mantine/notifications/styles.css';
import {Notifications} from "@mantine/notifications";

//-------------------------------------------------------------------------------------------------------------------

const rootElement = document.getElementById('root');

ReactDOM.render(
    <BrowserRouter>
        <MantineProvider>
            <Notifications />
            <App />    
        </MantineProvider>
    </BrowserRouter>,
  rootElement);

//registerServiceWorker();

