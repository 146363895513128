import React from 'react';
import ExpandingTextArea from 'react-expanding-textarea';

// Services & Helpers
import TextHelpers from 'helpers/TextHelpers';
import API from 'API';

// Components
import CurrencyInput from 'components/common/CurrencyInput';
import SearchBox from 'components/common/SearchBox';
import Loader from 'components/common/Loader';

//-----------------------------------------------------------------

class ItemsTable extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoadingProduct: {}
        };
    }

    async loadProduct(index, id) {
        // Set loading
        let isLoadingProduct = { ...this.state.isLoadingProduct };
        isLoadingProduct[index] = true;
        this.setState({ isLoadingProduct });

        // Load
        const product = await API.call('product/get/' + id);

        // Update UI
        this.props.onChange(index, {
            productID: product.id,
            product,
            description: product.name,
            unitPrice: product.unitPrice,
            unitType: product.unitType
        });

        // Set not loading
        isLoadingProduct = { ...this.state.isLoadingProduct };
        delete isLoadingProduct[index];
        this.setState({ isLoadingProduct });
    }

    getRowClass(item) {
        const remaining = (parseInt(item.quantity) || 0) - (item.quantityDispatched || 0) - (item.quantityToDispatch || 0);
        if (remaining < 0) {
            return 'row-negative';
        }
        return '';
    }

    render() {
        const {
            onChange,
            onDelete,
            showPrice,
            mode
        } = this.props;

        // Get totals
        let items = [...this.props.items];
        let total = 0, totalQuantity = 0, totalDispatched = 0, totalToDispatch = 0;
        items.forEach(i => {
            total += Number(i.total || 0);
            totalQuantity += parseInt(i.quantity || 0);
            totalDispatched += parseInt(i.quantityDispatched || 0);
            totalToDispatch += parseInt(i.quantityToDispatch || 0);
        });

        // Blank line for adding new items
        if (mode == 'Job' || mode == 'PurchaseOrder') {
            if (!items.find(i => i.isNew)) {
                items.push({ isNew: true });
            }
        }
        const qtyLabel = (mode == 'JobDelivery' ? 'Ordered' : 'Qty');

        return (
            <table className="items-table table table-bordered mb-0">
                <thead>
                    <tr>
                        {mode == 'Job' &&
                            <th className="product-col">Product</th>
                        }
                        <th className="description-col">Description</th>
                        <th className="qty-col">{qtyLabel}</th>
                        {showPrice && <>
                            <th className="price-col" colSpan={2}>Unit Price</th>
                            <th className="total-col">Total</th>
                        </>}
                        <th className="dispatched-col">
                            {(mode == 'Job' || mode == 'JobDelivery') ? <>
                                Dispatched
                            </> : <>
                                Received
                            </>}
                        </th>
                        {(mode == 'JobDelivery' || mode == 'PurchaseOrderDelivery') && <>
                            <th className="to-dispatch-col">
                                {mode == 'JobDelivery' ?
                                    <>To dispatch</> :
                                    <>To receive</>
                                }
                            </th>
                        </>}
                        <th className="remaining-col">Remaining</th>
                    </tr>
                </thead>
                <tbody>
                    {items.map((item, index) =>
                        <tr key={index} className={this.getRowClass(item)}>
                            {mode == 'Job' &&
                                <td className="product-col">
                                    {this.renderItemProduct(item, index)}
                                </td>
                            }
                            <td className="description-col">
                                {mode == 'Job' || mode == 'PurchaseOrder' ?
                                    <ExpandingTextArea
                                        className="form-control"
                                        rows={1}
                                        placeholder="Start typing to add an item..."
                                        value={item.description || ''}
                                        onChange={e => onChange(index, { description: e.target.value })}
                                    /> : 
                                    <p className="form-control-plaintext">
                                        {item.description}
                                    </p>
                                }
                            </td>
                            <td className="qty-col">
                                {!item.isNew && <>
                                    {mode == 'Job' || mode == 'PurchaseOrder' ?
                                        <input
                                            type="number"
                                            className="form-control"
                                            value={item.quantity || ''}
                                            onChange={e => onChange(index, { quantity: e.target.value })}
                                            onWheel={e => {
                                                e.preventDefault();
                                                e.stopPropagation();
                                                e.currentTarget.blur();
                                            }}
                                        /> :
                                        <p className="form-control-plaintext">
                                            {item.quantity}
                                        </p>
                                    }
                                </>}
                            </td>
                            {showPrice && <>
                                <td className="price-col">
                                    {!item.isNew && <>
                                        {mode == 'Job' || mode == 'PurchaseOrder' ?
                                            <CurrencyInput
                                                className="form-control"
                                                value={item.unitPrice || ''}
                                                onValueChange={(values) => onChange(index, { unitPrice: values.value })}
                                            /> :
                                            <p className="form-control-plaintext">
                                                {TextHelpers.formatCurrency(item.unitPrice || '')}
                                            </p>
                                        }
                                    </>}
                                </td>
                                <td className="unit-type-col">
                                    {!item.isNew && <>
                                        {mode == 'Job' || mode == 'PurchaseOrder' ?
                                            <select
                                                className="form-control"
                                                value={item.unitType || ''}
                                                onChange={e => onChange(index, { unitType: e.target.value })}
                                            >
                                                <option value=""></option>
                                                <option value="Each">Each</option>
                                                <option value="Sets">Per Set</option>
                                                <option value="Pairs">Per Pair</option>
                                            </select> :
                                            <p className="form-control-plaintext">
                                                {TextHelpers.getFriendlyName('UnitType', item.unitType)}
                                            </p>
                                        }
                                    </>}
                                </td>
                                <td className="total-col">
                                    {!item.isNew &&
                                        <p className="form-control-plaintext">
                                            {TextHelpers.formatCurrency(item.total || '')}
                                        </p>
                                    }
                                </td>
                            </>}
                            <td className="dispatched-col">
                                {!item.isNew &&
                                    <p className="form-control-plaintext">
                                        {item.quantityDispatched}
                                    </p>
                                }
                            </td>
                            {(mode == 'JobDelivery' || mode == 'PurchaseOrderDelivery') &&
                                <td className="to-dispatch-col">
                                    <input
                                        type="number"
                                        className="form-control"
                                        value={item.quantityToDispatch || ''}
                                        onChange={e => onChange(index, { quantityToDispatch: e.target.value })}
                                        onWheel={e => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                            e.currentTarget.blur();
                                        }
                                    }
                                    />
                                </td>
                            }
                            <td className="remaining-col floating-controls">
                                {!item.isNew && <>
                                    <div>
                                        <p className="form-control-plaintext">
                                            {(parseInt(item.quantity) || 0) - (item.quantityDispatched || 0) - (item.quantityToDispatch || 0)}
                                        </p>

                                        {(mode == 'Job' || mode == 'PurchaseOrder') && onDelete &&
                                            <div className="btns">

                                                <button type="button" className="btn btn-danger" onClick={() => onDelete(item, index)}>
                                                    <span className="fa-solid fa-times" />
                                                </button>

                                            </div>
                                        }
                                    </div>
                                </>}
                            </td>
                        </tr>
                    )}
                    {items.length > 1 &&
                        <tr className="total-row">
                            <td className="product-col" colSpan={2}>Total</td>
                            <td className="qty-col">{totalQuantity}</td>
                            {showPrice && <>
                                <td className="price-col"></td>
                                <td className="unit-type-col"></td>
                                <td className="total-col">
                                    {TextHelpers.formatCurrency(total)}
                                </td>
                            </>}
                            <td className="dispatched-col">
                                {totalDispatched}
                            </td>
                            {(mode == 'JobDelivery' || mode == 'PurchaseOrderDelivery') &&
                                <td className="to-dispatch-col">
                                    {totalToDispatch}
                                </td>
                            }
                            <td className="remaining-col">
                                {totalQuantity - totalDispatched - totalToDispatch}
                            </td>
                        </tr>
                    }
                </tbody>
            </table>
        );
    }

    renderItemProduct(item, index) {
        const { isLoadingProduct } = this.state;
        const {
            onChange
        } = this.props;

        if (item.product) {
            return (
                <p className="form-control-plaintext">
                    {item.product.code}
                    <button className="btn btn-sm btn-danger fa fa-times ms-2" title="Select a different product" onClick={() => onChange(index, { productID: null, product: null })} />
                </p>
            );
        }

        if (isLoadingProduct[index]) {
            return (<Loader isInline />);
        }

        return (
            <SearchBox
                className="form-control"
                placeholder="Search..."
                minLength={2}
                search={async (query, setResults, dataObj) => {
                    dataObj.nonce = `${Math.random()}`;
                    const response = await API.call('search', { query, nonce: dataObj.nonce, types: ['Product'] });
                    if (response.nonce == dataObj.nonce) {
                        setResults(response.results);
                    }
                }}
                onClickResult={product => this.loadProduct(index, product.id)}
            />
        );
    }
}

export default ItemsTable;
