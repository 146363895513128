import {forwardRef, useImperativeHandle, useState} from "react";
import {useDisclosure} from "@mantine/hooks";
import {Button, Checkbox, Fieldset, Group, Input, Modal, Space, Stack, TextInput} from "@mantine/core";
import API from 'API';

const EditOpenOrderModal = forwardRef(({onOpenOrderSaved}, ref) => {
    const [opened, { open, close }] = useDisclosure(false);
    const [openOrder, setOpenOrder] = useState({ supplierPONumber: '', isThirdParty: false });

    useImperativeHandle(ref, () => {
        return {
            open(openOrder) {
                open();
                setOpenOrder(openOrder);
            }
        }
    });

    const saveAndClose = async () => {
        await API.call('open-orders/save', openOrder);
        close();
        onOpenOrderSaved(openOrder);
    }
    
    if (openOrder == undefined) {
        return null;
    }
 
    return (
        <>
            <Modal 
                opened={opened} 
                onClose={close}
                centered
                withCloseButton={false}
                size="auto"
                overlayProps={{
                    backgroundOpacity: 0.55,
                    blur: 3,
                }}
            >
                <Stack>
                    <TextInput 
                        label="Supplier PO"
                        value={openOrder.supplierPONumber}
                        onChange={(e) => setOpenOrder({ ...openOrder, supplierPONumber: e.target.value})}
                    />
                    
                    <Checkbox 
                        label="Third party"
                        labelPosition="left"
                        checked={openOrder.isThirdParty}
                        onChange={(e) => setOpenOrder({ ...openOrder, isThirdParty: e.target.checked})}
                    />

                    <Group justify="space-between">
                        <Button onClick={() => close()}>Close</Button>
                        <Button onClick={() => saveAndClose()}>Save and close</Button>
                    </Group>
                </Stack>
            </Modal>
        </>
    )
})

export default EditOpenOrderModal;